import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button, PrimaryButton, SecondaryButton, TertiaryButton, SuccessButton, NegativeButton, ButtonGroup } from '@entur/button';
import { SmallExpandableAlertBox } from '@entur/alert';
import { Props } from 'docz';
import PageHeader from '~/components/PageHeader';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import { ImportStatement } from '~/components/ImportStatement';
import ComponentRuler from '~/components/ComponentRuler';
import { Playground } from '~/components/playground/Playground';
import doPic from './ButtonDo.png';
import dontPic from './ButtonDont.png';
import { RefreshIcon, AddIcon, ForwardIcon } from '@entur/icons';
import { standardknapper } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<Button></Button>'} __scope={{
      props,
      DefaultLayout,
      Button,
      PrimaryButton,
      SecondaryButton,
      TertiaryButton,
      SuccessButton,
      NegativeButton,
      ButtonGroup,
      SmallExpandableAlertBox,
      Props,
      PageHeader,
      DoDontGroup,
      DoDontCard,
      ImportStatement,
      ComponentRuler,
      Playground,
      doPic,
      dontPic,
      RefreshIcon,
      AddIcon,
      ForwardIcon,
      standardknapper,
      DefaultLayout,
      _frontmatter
    }} props={standardknapper} mdxType="Playground">
  <Button mdxType="Button"></Button>
    </Playground>
    <h2 {...{
      "id": "bruk"
    }}>{`Bruk`}</h2>
    <h3 {...{
      "id": "varianter"
    }}>{`Varianter`}</h3>
    <h4 {...{
      "id": "positive-svært-høyt-prioritert"
    }}>{`Positive (Svært høyt prioritert):`}</h4>
    <p>{`Indikerer en vellykket eller positiv handling. Brukes som “call to action” for svært høyt prioriterte handlinger. Hovedregelen her er kun én action knapp per side. Knappen kan brukes for handlinger som f.eks Betal, Godkjenn osv.`}</p>
    <h4 {...{
      "id": "primary-høy-prioritet"
    }}>{`Primary (Høy prioritet):`}</h4>
    <p>{`Brukes kun til primær handlinger som har høyt prioritert på en side. Det bør ikke være mer enn én slik knapp per side eller visning. Kan brukes for handlinger som f.eks Lagre, Send, Gå videre osv.`}</p>
    <h4 {...{
      "id": "secondary-middels-prioritet"
    }}>{`Secondary (Middels prioritet):`}</h4>
    <p>{`Subtil variant som brukes til å indikere en sekundær handling som komplimenterer en primær handling. Middels prioriterte handlinger og dersom man ikke ønsker at den skal være i fokus eller for å redusere visuell støy når det er mange handlinger av like stor prioritet på siden.`}</p>
    <h4 {...{
      "id": "negative"
    }}>{`Negative:`}</h4>
    <p>{`Indikerer en fare eller potensielt negativ handling. Brukes kun for å advare brukeren mot handlinger som kan føre til negative konsekvenser som f.eks Slett, Fjern, Avbryt osv. Slike handlinger må alltid bekreftes.`}</p>
    <h4 {...{
      "id": "tertiary"
    }}>{`Tertiary`}</h4>
    <SmallExpandableAlertBox variant="warning" title="Tertiary button er deprekert!" mdxType="SmallExpandableAlertBox">
  Tertiærknappen er deprektert til fordel for SecondaryButton i size="small".
  Tertiærknappen er fortsatt tilgjengelgi i @entur/button, men vil på sikt bli
  fjernet. Vi jobber også med å se på behovet for en ny knappetype som kan
  brukes i tilfeller der man trenger en knapp som hierakrisk ligger mellom
  IconButton og SecondaryButton.
    </SmallExpandableAlertBox>
    <br />
    <p>{`Tertiary brukes på steder hvor de andre knappevariantene bruker for stor plass, og en mindre knapp er nødvendig. Alternativt kan man også bruke ActionChip for dette behovet, men tertiærknappen finnes som et alternativ til den.`}</p>
    <h3 {...{
      "id": "knapp-med-ikon-og-tekst"
    }}>{`Knapp med ikon og tekst`}</h3>
    <p>{`Når navngivingen på knappen ikke er tydelig nok, kan man vurdere å bruke
ikoner i knapper for å kommunisere tydeligere hva knappen gjør. Ikoner er
alltid sammen med en tekst.`}</p>
    <Playground __position={3} __code={'<ButtonGroup>\n  <SecondaryButton>\n    <AddIcon /> Legg til kunde\n  </SecondaryButton>\n  <SuccessButton>\n    Gå videre <ForwardIcon />\n  </SuccessButton>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Button,
      PrimaryButton,
      SecondaryButton,
      TertiaryButton,
      SuccessButton,
      NegativeButton,
      ButtonGroup,
      SmallExpandableAlertBox,
      Props,
      PageHeader,
      DoDontGroup,
      DoDontCard,
      ImportStatement,
      ComponentRuler,
      Playground,
      doPic,
      dontPic,
      RefreshIcon,
      AddIcon,
      ForwardIcon,
      standardknapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <SecondaryButton mdxType="SecondaryButton">
      <AddIcon mdxType="AddIcon" /> Legg til kunde
    </SecondaryButton>
    <SuccessButton mdxType="SuccessButton">
      Gå videre <ForwardIcon mdxType="ForwardIcon" />
    </SuccessButton>
  </ButtonGroup>
    </Playground>
    <h3 {...{
      "id": "størrelser"
    }}>{`Størrelser`}</h3>
    <p>{`Standardstørrelsen på våre knappene er “medium”. Trenger man en knapp som tar mer fokus kan man bruke "large" med en større høyde.
Hvis man ønsker en knapp med mindre fokus eller som tar mindre plass kan man bruke size="small".`}</p>
    <ComponentRuler.Wrapper direction="vertical">
  <ComponentRuler xName="Min-bredde" xNumber="5.75rem" yName="Høyde" yNumber="2rem" mdxType="ComponentRuler">
    <PrimaryButton size="small" mdxType="PrimaryButton">Liten</PrimaryButton>
  </ComponentRuler>
  <ComponentRuler xName="Min-bredde" xNumber="9.5rem" yName="Høyde" yNumber="3rem" mdxType="ComponentRuler">
    <PrimaryButton mdxType="PrimaryButton">Vanlig</PrimaryButton>
  </ComponentRuler>
  <ComponentRuler xName="Min-bredde" xNumber="11.75rem" yName="Høyde" yNumber="3.75rem" mdxType="ComponentRuler">
    <PrimaryButton size="large" mdxType="PrimaryButton">Stor</PrimaryButton>
  </ComponentRuler>
    </ComponentRuler.Wrapper>
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <p>{`Kort oppsummert: Unngå inaktive knapper så godt det lar seg gjøre.`}</p>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard title="Gjør" variant="success" src={doPic} mdxType="DoDontCard">
    <ul>
      <li>
        Aktiv knapp som validerer (når man klikker på den) erstatter bruk av
        disabled (inaktiv) knapp
      </li>
      <li>
        Validering av påkrevde felter skal skje underveis i skjema, men først
        når man går ut av inputfeltet
      </li>
      <li>
        Hvis man ikke går inn i feltet vil validering først komme når man
        validerer siden (ved f.eks å klikke “lagre” eller lignende). Det gjelder
        også for felter som validerer mot baksystemer
      </li>
    </ul>
  </DoDontCard>
  <DoDontCard title="Unngå" variant="warning" src={dontPic} mdxType="DoDontCard">
    <ul>
      <li>
        Inaktive knapper bør unngås fordi den skaper vanligvis en elendig
        brukeropplevelse og utelukker mange mennesker med funksjonshemninger
      </li>
      <li>
        Når du ved et uhell klikker på dem, får du ingen konkret tilbakemelding
      </li>
      <li>
        Irritasjonsmoment, brukeren skjønner ikke hva knappen sier eller hvorfor
        den ikke er klikkbar
      </li>
    </ul>
  </DoDontCard>
    </DoDontGroup>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "primarybutton"
    }}>{`PrimaryButton`}</h3>
    <ImportStatement imports="PrimaryButton" mdxType="ImportStatement" />
    <Props of={PrimaryButton} mdxType="Props" />
    <h3 {...{
      "id": "secondarybutton"
    }}>{`SecondaryButton`}</h3>
    <ImportStatement imports="SecondaryButton" mdxType="ImportStatement" />
    <Props of={SecondaryButton} mdxType="Props" />
    <h3 {...{
      "id": "successbutton"
    }}>{`SuccessButton`}</h3>
    <ImportStatement imports="SuccessButton" mdxType="ImportStatement" />
    <Props of={SuccessButton} mdxType="Props" />
    <h3 {...{
      "id": "negativebutton"
    }}>{`NegativeButton`}</h3>
    <ImportStatement imports="NegativeButton" mdxType="ImportStatement" />
    <Props of={NegativeButton} mdxType="Props" />
    <h3 {...{
      "id": "button"
    }}>{`Button`}</h3>
    <ImportStatement imports="Button" mdxType="ImportStatement" />
    <Props of={Button} mdxType="Props" />
    <p><inlineCode parentName="p">{`<Button />`}</inlineCode>{` er grunn-komponenten som lar deg lage de andre knappene. Du vil typisk bruke en av de andre komponentene direkte.`}</p>
    <h3 {...{
      "id": "buttongroup"
    }}>{`ButtonGroup`}</h3>
    <ImportStatement imports="ButtonGroup" mdxType="ImportStatement" />
    <Props of={ButtonGroup} mdxType="Props" />
    <p>{`Om du skal ha flere knapper ved siden av hverandre, bør du wrappe dem i en `}<inlineCode parentName="p">{`<ButtonGroup />`}</inlineCode>{`. Dette gir deg riktig avstand mellom knappene.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      